import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { LoginForm } from "../utils/user.models";
import { Session } from "../utils/session.models";

@Injectable({
  providedIn: "root",
})
export class HttpAuthService {
  private http = inject(HttpClient);

  confirmNewUserAccount(token: string, password: string): Observable<void> {
    const url = `${environment.api}/v1/auth/verify-email`;
    return this.http.post<void>(url, {
      oob_code: token,
      password: password,
    });
  }

  forgotPassword(email: string): Observable<void> {
    const url = `${environment.api}/v1/auth/reset-password/email`;
    return this.http.post<void>(url, { email });
  }

  login(form: LoginForm): Observable<Session> {
    const url = `${environment.api}/v1/auth/login`;
    return this.http.post<Session>(url, form);
  }

  logout(): Observable<void> {
    const url = `${environment.api}/v1/auth/logout`;
    return this.http.post<void>(url, {});
  }
}
