import { inject, Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  finalize,
  map,
  Observable,
  take,
  tap,
} from "rxjs";
import { LOCAL_STORAGE_USER_KEY } from "../../utils/app.constants.utils";
import { LoginForm } from "../utils/user.models";
import { HttpAuthService } from "./http-auth.service";
import { MessageService } from "primeng/api";
import { routes } from "../../../../environments/environment.routes";
import { Session } from "../utils/session.models";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  readonly userSession = new BehaviorSubject<Session | null>(
    localStorage.getItem(LOCAL_STORAGE_USER_KEY)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY) as string)
      : null,
  );

  private router = inject(Router);
  private http = inject(HttpAuthService);
  private message = inject(MessageService);

  get user$() {
    return this.userSession.pipe(map((session) => session?.user));
  }

  isAuthenticated(): boolean | UrlTree {
    return !!this.userSession.value || this.router.parseUrl("/auth/login");
  }

  login(form: LoginForm): void {
    this.http
      .login(form)
      .pipe(
        take(1),
        tap((session) => {
          this.userSession.next(session);

          localStorage.setItem(
            LOCAL_STORAGE_USER_KEY,
            JSON.stringify(this.userSession.value),
          );

          this.router.navigate(["/"]);
        }),
        catchError(() => {
          this.message.add({
            severity: "error",
            summary: "Error",
            detail: "Failed to login. Please try again.",
          });
          return EMPTY;
        }),
      )
      .subscribe();
  }

  logout(): void {
    this.http
      .logout()
      .pipe(
        take(1),
        finalize(() => {
          this.clearSession();
        }),
      )
      .subscribe();
  }

  private clearSession() {
    this.userSession.next(null);
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    this.router.navigate([routes.frontend.login]);
  }

  forgotPassword(email: string): Observable<unknown> {
    return this.http.forgotPassword(email).pipe(
      take(1),
      tap(() => {
        this.message.add({
          severity: "success",
          summary: "Success",
          detail: "Please check your email inbox for a verification code.",
          life: 5000,
        });
      }),
      catchError(() => {
        this.message.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to send password reset email. Please try again.",
        });
        return EMPTY;
      }),
    );
  }

  confirmNewUserAccount(token: string, password: string): void {
    if (!token || !password) return;

    this.http
      .confirmNewUserAccount(token, password)
      .pipe(
        take(1),
        tap(() => {
          this.message.add({
            severity: "success",
            summary: "Success",
            detail: "Password has been reset. Please log in again.",
            life: 5000,
          });

          this.router.navigate([routes.frontend.login]);
        }),
        catchError(() => {
          this.message.add({
            severity: "error",
            summary: "Error",
            detail: "Failed to reset password. Please try again.",
          });
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
